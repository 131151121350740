import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetRedirectPasswordAuthority } from './constants/msal.config';
import { AuthService } from './services/auth.service';
import { VersionCheckService } from './services/version-check.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(private authService: AuthService,
    private msalAuthService: MsalService,
    private route: Router,
    private msalBroadcastService: MsalBroadcastService,
    private versionCheckService: VersionCheckService) {

  }

  ngOnInit() {
    this.versionCheckService.startVersionChecking({ notification: null, frequency: 60000*20 });
    this.isIframe = window !== window.parent && !window.opener;
    if (this.authService.getActiveAccount() != null) {
      this.route.navigate(['/home']);
    }
    this.authService.activeAccountSubject$.subscribe((accountInfo) => {
      if (accountInfo) {
        this.route.navigate(['/home']);
      }
    });

    this.msalAuthService.handleRedirectObservable().pipe(filter((result: AuthenticationResult) => result != null)).subscribe({
      next: (result: AuthenticationResult) => {
        if (result != null) {
          if (result.idTokenClaims) {
            const tfp = result.idTokenClaims['tfp'];
            if (tfp === environment.abodeaPwdPolicy || tfp === environment.rentCredPwdPolicy) {
              // this.authService.logout();
              this.route.navigateByUrl("/home");
            }
          }
        }
      },
      error: (error) => {
        if (error.errorMessage.indexOf("AADB2C90091") > -1) {
          if(error.errorMessage.includes("cancelled")){
            return;
          }
          //const authority = 'https://rentcredhubb2cuat.b2clogin.com/rentcredhubb2cuat.onmicrosoft.com/B2C_1_passwordreset';
          this.msalAuthService.loginRedirect({
            account: this.msalAuthService.instance.getActiveAccount(),
            authority: GetRedirectPasswordAuthority(),
            scopes: ['openid', 'offline_access']
          });
        }
        return;
      }
    });
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
