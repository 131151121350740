import { BrowserCacheLocation, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export const b2cPolicies = {
    names: {
        signUpSignIn: environment.rentCredSignInPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: environment.authority,
        },
        resetpassword: {
            authority: environment.resetPasswordAuthority,
            scopes: ['openid', 'offline_access']

        }
    },
    authorityDomain: environment.authorityDomain
};

export const b2cPoliciesAbodea = {
    names: {
        signUpSignIn: environment.abodeaSignInPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: environment.abodeaauthority,
        },
        resetpassword: {
            authority: environment.abodearesetPasswordAuthority,
            scopes: ['openid', 'offline_access']

        }
    },
    authorityDomain: environment.authorityDomain
};

export const protectedResources = {
    rentcredapi: {
        endpoint: environment.apiBaseUrl + "/*",
        scopes: [environment.scopes],
    },
}

export function GetPublicClientApplicationForMSALInstanceFactory() {
    let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    let brandName: HTMLLinkElement = document.querySelector('#brandName');
    let domainUrl = (document.getElementsByTagName('base')[0].href);
    domainUrl = domainUrl.substring(0, domainUrl.length - 1)
  if (domainUrl == environment.baseUrl) {
        favIcon.href = 'favicon.ico';
        brandName.innerText = 'CredHub';
        return new PublicClientApplication({
            auth: {
                clientId: environment.clientID, // This is the ONLY mandatory field that you need to supply.
                authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
                knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
                redirectUri: '/',
                postLogoutRedirectUri: '/'            
            },
            cache: {
                cacheLocation: BrowserCacheLocation.SessionStorage,
                storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false
                }
            }
        });
    } else if (domainUrl == environment.abodeabaseUrl) {
        favIcon.href = 'AB_Favicon.ico';
        brandName.innerText = 'Abodea';
        return new PublicClientApplication({
            auth: {
                clientId: environment.clientID, // This is the ONLY mandatory field that you need to supply.
                authority: b2cPoliciesAbodea.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
                knownAuthorities: [b2cPoliciesAbodea.authorityDomain], // Mark your B2C tenant's domain as trusted.
                redirectUri: '/',
                postLogoutRedirectUri: '/'
            },
            cache: {
                cacheLocation: BrowserCacheLocation.SessionStorage,
                storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false
                }
            }
        });
    } else {
        favIcon.href = 'favicon.ico';
        brandName.innerText = 'CredHub';
        return new PublicClientApplication({
            auth: {
                clientId: environment.clientID, // This is the ONLY mandatory field that you need to supply.
                authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
                knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
                redirectUri: '/',
                postLogoutRedirectUri: '/'
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false
                }
            }
        });
    }
}

export function GetProtectedResourceMapForMSALInterceptorConfigFactory() {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(protectedResources.rentcredapi.endpoint, protectedResources.rentcredapi.scopes);
    return protectedResourceMap;
}

export function GetRedirectPasswordAuthority() {
    let domainUrl = (document.getElementsByTagName('base')[0].href);
    domainUrl = domainUrl.substring(0, domainUrl.length - 1)
    if (domainUrl == environment.baseUrl) {
        return b2cPolicies.authorities.resetpassword.authority;
    } else {
        return b2cPoliciesAbodea.authorities.resetpassword.authority;
    }
}

export function GetBaseUrl(){
    let domainUrl = (document.getElementsByTagName('base')[0].href);
    domainUrl = domainUrl.substring(0, domainUrl.length - 1)
    if (domainUrl == environment.baseUrl) {
        return environment.baseUrl;
    } else {
        return environment.abodeabaseUrl;
    }
}
































// export function GetConfiguration() {
//     let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
//     let brandName: HTMLLinkElement = document.querySelector('#brandName');
//     let domainUrl = (document.getElementsByTagName('base')[0].href);
//     domainUrl = domainUrl.substring(0, domainUrl.length - 1)
//     if (domainUrl == environment.baseUrl) {
//         favIcon.href = 'favicon.ico';
//         brandName.innerText = 'CredHub';
//         return {
//             clientID: environment.clientID,
//             validateAuthority: false,
//             cacheLocation: 'localStorage',
//             baseUrl: environment.baseUrl,
//             piiLoggingEnabled: true,
//             isAngular: true,
//             protectedResourceMap: protectedApis,
//             logger: loggerCallback,
//             navigateToLoginRequestUrl: true,
//             authority: environment.authority + environment.rentCredSignInPolicy,
//             postLogoutRedirectUri: environment.baseUrl + '/',
//             redirectUri: environment.baseUrl + '/',
//             resetPasswordAuthority: environment.resetPasswordAuthority + environment.rentCredPwdPolicy
//         }
//     }
//     else if (domainUrl == environment.abodeabaseUrl) {
//         favIcon.href = 'AB_Favicon.ico';
//         brandName.innerText = 'Abodea';
//         return {
//             clientID: environment.clientID,
//             validateAuthority: false,
//             cacheLocation: 'localStorage',
//             baseUrl: environment.abodeabaseUrl,
//             piiLoggingEnabled: true,
//             isAngular: true,
//             protectedResourceMap: protectedApis,
//             logger: loggerCallback,
//             navigateToLoginRequestUrl: true,
//             authority: environment.authority + environment.abodeaSignInPolicy,
//             postLogoutRedirectUri: environment.abodeabaseUrl + '/',
//             redirectUri: environment.abodeabaseUrl + '/',
//             resetPasswordAuthority: environment.resetPasswordAuthority + environment.abodeaPwdPolicy
//         }
//     }
//     else {
//         favIcon.href = 'favicon.ico';
//         brandName.innerText = 'CredHub';
//         return {
//             clientID: environment.clientID,
//             validateAuthority: false,
//             cacheLocation: 'localStorage',
//             baseUrl: environment.baseUrl,
//             piiLoggingEnabled: true,
//             isAngular: true,
//             protectedResourceMap: protectedApis,
//             logger: loggerCallback,
//             navigateToLoginRequestUrl: true,
//             authority: environment.authority + environment.rentCredSignInPolicy,
//             postLogoutRedirectUri: environment.baseUrl + '/',
//             redirectUri: environment.baseUrl + '/',
//             resetPasswordAuthority: environment.resetPasswordAuthority + environment.rentCredPwdPolicy
//         }
//     }
// }


