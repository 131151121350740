export const environment = {
  appVersion: 'Dev - ',
  production: true,
  appInsights: {
    instrumentationKey: '724086d8-beb1-466a-bf65-a3d9f1be9a65'
    // instrumentationKey: ''
  },
  apiBaseUrl: 'https://credhubdev-webapi.azurewebsites.net/api',
  baseUrl: 'https://credhubdev-webapp.azurewebsites.net',
  alertDisplayTimeout: 4000, // 4 seconds,
  
  clientID: '17b7d980-c76a-4708-8d1c-8da3ba419462',
  authority: 'https://credhubb2cad.b2clogin.com/credhubb2cad.onmicrosoft.com/b2c_1_signupsignin',
  resetPasswordAuthority: 'https://credhubb2cad.b2clogin.com/credhubb2cad.onmicrosoft.com/B2C_1_passwordreset',
  cryptoKey: 'iMniAuUG9kiGLwBt',
  rentCredSignInPolicy: 'b2c_1_signupsignin',
  rentCredPwdPolicy: 'B2C_1_passwordreset',
  authorityDomain: 'credhubb2cad.b2clogin.com',
  scopes: "https://credhubb2cad.onmicrosoft.com/d60c4980-a47f-434a-9beb-8530df17e17c/user_impersonation",

  // Abodea Config
  abodeaSignInPolicy: 'b2c_1_abodeasignupsignin',
  abodeaPwdPolicy: 'B2C_1_abodeapasswordreset',
  abodeabaseUrl: 'https://creditcaredev.rentcred.us',
  abodeaauthority:'https://credhubb2cad.b2clogin.com/credhubb2cad.onmicrosoft.com/b2c_1_abodeasignupsignin',
  abodearesetPasswordAuthority: 'https://credhubb2cad.b2clogin.com/credhubb2cad.onmicrosoft.com/B2C_1_abodeapasswordreset'
};
