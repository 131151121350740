import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
//import { MsalService } from '@azure/msal-angular/dist/msal.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  title = 'Microsoft identity platform';
  private readonly _destroying$ = new Subject<void>();
  constructor(private authService: AuthService, private route: Router, private msalBroadcastService: MsalBroadcastService) { }
  ngOnInit(): void {
    if (this.authService.getActiveAccount() == null) {
      this.authService.updateLoggedInStatus();
      this.authService.activeAccountSubject$.subscribe((accountInfo) => {
        if (accountInfo) {
          this.route.navigate(['/home']);
        }
      });
    } else {
      this.route.navigateByUrl("/home");
    }


    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events.
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Do something with the result

        if (result.eventType === "msal:loginSuccess") {
          if (result.payload) {
            if (result.payload['idTokenClaims']) {
              const tfp = result.payload['idTokenClaims'].tfp;
              if (tfp === environment.abodeaPwdPolicy || tfp === environment.rentCredPwdPolicy) {
                // this.authService.logout();
                this.route.navigateByUrl("/home");
              }
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
