import { Inject, Injectable, OnDestroy } from '@angular/core';
//import { GetConfiguration } from '../constants/msal.config';
//import { MsalService as MSAL } from '@azure/msal-angular/dist/msal.service';
import { environment } from '../../environments/environment';
import { MsalBroadcastService, MsalService, MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountInfo, AuthenticationResult, BrowserUtils, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { GetBaseUrl, GetRedirectPasswordAuthority } from '../constants/msal.config';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  activeAccountSubject$ = new BehaviorSubject<AccountInfo>(null);
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService
      ) { }

  updateLoggedInStatus() {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalAuthService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        // Perform actions related to user accounts here
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      },
      error: (error) => {
        console.log(error.message);
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
          this.msalAuthService.loginRedirect({
            authority: GetRedirectPasswordAuthority()
          } as RedirectRequest);
        } else {
          this.msalAuthService.logout();
        }
      }
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events.
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Do something with the result      
        if (result.eventType === "msal:loginSuccess") {
          if (result.payload) {
            if (result.payload['idTokenClaims']) {
              const tfp = result.payload['idTokenClaims'].tfp;
              if (tfp === environment.abodeaPwdPolicy || tfp === environment.rentCredPwdPolicy) {
                this.logout();
              }
            }
          }
        }
      });
  }

  getActiveAccount(): AccountInfo | null {
    return this.msalAuthService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount() {
    /**
    * If no active account set but there are accounts signed in, sets first account to active account
    * To use active account set here, subscribe to inProgress$ first in your component
    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    */
    let activeAccount = this.msalAuthService.instance.getActiveAccount();
    if (activeAccount == null && this.msalAuthService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
      this.activeAccountSubject$.next(this.msalAuthService.instance.getActiveAccount());
    }

    console.log('total accounts');
    console.log(this.msalAuthService.instance.getAllAccounts().length);
  }

  private setLoggedIn() {
    this.loggedIn = this.msalAuthService.instance.getAllAccounts().length > 0;
    if (!this.loggedIn) {
      localStorage.clear();
      sessionStorage.clear();
      this.login();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalAuthService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalAuthService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalAuthService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalAuthService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.msalAuthService.loginRedirect({ ...userFlowRequest });
      }
    }
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  logout(): void {
    localStorage.removeItem('IsSalesforceLogin');
    localStorage.clear();
    sessionStorage.clear();
    let accounts = this.msalAuthService.instance.getAllAccounts();
    // this.msalAuthService.instance.setActiveAccount(null);
    this.msalAuthService.instance.logoutRedirect({ account: accounts[0], postLogoutRedirectUri: GetBaseUrl() });
    // this.msalAuthService.logout();
  }

  isLoggedIn(): boolean {
    return this.msalAuthService.instance.getActiveAccount() !== null;
  }

  getUserEmail() {
    return this.msalAuthService.instance.getActiveAccount().idTokenClaims['emails'][0];
  }

  getUser() {
    return this.getActiveAccount();
  }

  resetPassword() {
    this.msalAuthService.loginRedirect({
      authority: GetRedirectPasswordAuthority(),
      scopes: ['openid', 'offline_access']
    } as RedirectRequest);
  }
}
